// mixins utils
// import { mergeDateTime } from "utils/mixins/mixins"
import { ACTIONS } from "utils/reducer/actions"

// moment
import moment from "moment"
// moment.tz.setDefault("Asia/Jakarta")

const eventScheduleConfiguration = param => {
  const { blocks } = param
  const acfMainEvent = blocks.find(block => block.name === "acf/main-event")
  const event = acfMainEvent.MainEvent
  const { eventDatetime } = event

  const {
    eventStartDate,
    eventStartTime,
    eventEndTime,
    eventEndDate,
    eventType,
  } = eventDatetime

  const currentDate = moment().valueOf()
  const eventStart = moment(eventStartDate + " " + eventStartTime).valueOf()
  const eventEnded = !!eventEndDate
    ? moment(eventEndDate + " " + eventEndTime).valueOf()
    : ""

  const eventLiveEnded = !!eventEndTime
    ? moment(eventStartDate + " " + eventEndTime).valueOf()
    : ""

  if (eventType === "reccuring") {
    let liveStart = moment()
      .set({
        hour: eventStartTime.split(":")[0],
        minute: eventStartTime.split(":")[1],
      })
      .valueOf()

    let liveEnded = moment()
      .set({
        hour: eventEndTime.split(":")[0],
        minute: eventEndTime.split(":")[1],
      })
      .valueOf()

    return currentDate > eventEnded
      ? { ...param, status: "past" }
      : currentDate > liveStart &&
        currentDate < liveEnded &&
        currentDate > eventStart
      ? { ...param, status: "live" }
      : currentDate < eventEnded
      ? { ...param, status: "upcoming" }
      : param
  } else {
    if (eventLiveEnded) {
      return currentDate > eventLiveEnded
        ? { ...param, status: "past" }
        : currentDate > eventStart && currentDate < eventLiveEnded
        ? { ...param, status: "live" }
        : currentDate < eventStart
        ? { ...param, status: "upcoming" }
        : param
    } else {
      return currentDate > eventStart ? { ...param, status: "live" } : param
    }
  }
}

const eventsReducer = (events, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_EVENTS:
      if (Array.isArray(events)) {
        return events.map(event => {
          return eventScheduleConfiguration(event)
        })
      } else {
        return eventScheduleConfiguration(events)
      }

    default:
      return events
  }
}

export { eventsReducer }
