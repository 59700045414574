import React from "react"
import PropTypes from "prop-types"
import YouTube from "react-youtube"
import { Modal, useModal } from "../modal/modal"

import { ImgRatio, Link, BackgroundImage } from "../utils/utils"

// import { gsap } from "gsap"

export const Card = ({
  id,
  forwardKey,
  forwardRef,
  variant,
  colLeft,
  colRight,
  label,
  labelClassName,
  title,
  titleClassName,
  text,
  textClassName,
  icon,
  cardIconClassName,
  iconWidth,
  img,
  imgHeight,
  imgOverlay,
  link,
  target,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
}) => {
  const { isShowing, toggle } = useModal()

  const videoSrc = link.split("/watch?v=")
  let youtubeSrc = ""

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
    },
  }

  if (videoSrc[0] == "https://www.youtube.com") {
    youtubeSrc = videoSrc[1]
    return (
      <div
        className={`card card-${variant} ${className} card-youtube`}
        id={id}
        key={forwardKey}
        ref={forwardRef}
        onClick={toggle}
      >
        <div className="row row-0">
          <div className={`col-bg ${colLeft}`}>
            {icon && (
              <div className={`card-icon ${cardIconClassName}`}>
                <img
                  src={icon}
                  className={`img-fluid card-icon-img ${iconWidth}`}
                  alt={title || "Icon"}
                />
                <div className={`el-before`} />
                <div className={`el-after`} />
              </div>
            )}
            {img && (
              <div className={`card-bg ${cardBgClassName}`}>
                <BackgroundImage
                  Tag="div"
                  fluid={img}
                  className={`card-bg-img ${imgHeight}`}
                >
                  <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                  <ImgRatio />
                </BackgroundImage>
                <Modal
                  isShowing={isShowing}
                  hide={toggle}
                  className="modal-centered modal-youtube"
                  modalWrapperClassName="youtube"
                  appElement={`body`}
                >
                  <div className="modal-wrapper youtube">
                    <div className="video-background">
                      <YouTube
                        videoId={youtubeSrc}
                        opts={videoOptions}
                        className="video-embed"
                      />
                    </div>
                  </div>
                </Modal>
                <div className={`el-before`} />
                <div className={`el-after`} />
              </div>
            )}
          </div>
          <div className={`col-body ${colRight}`}>
            {(title || text) && (
              <div className={`card-body ${cardBodyClassName}`}>
                {label && (
                  <span className={`card-label ${labelClassName}`}>
                    {label}
                  </span>
                )}
                {title && (
                  <h3 className={`card-title ${titleClassName}`}>{title}</h3>
                )}
                {text && <p className={`card-text ${textClassName}`}>{text}</p>}
                {children}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <Link
        to={link}
        className={`card card-${variant} ${className} `}
        target={target}
        id={id}
        key={forwardKey}
        ref={forwardRef}
      >
        <div className="row row-0">
          <div className={`col-bg ${colLeft}`}>
            {icon && (
              <div className={`card-icon ${cardIconClassName}`}>
                <img
                  src={icon}
                  className={`img-fluid card-icon-img ${iconWidth}`}
                  alt={title || "Icon"}
                />
                <div className={`el-before`} />
                <div className={`el-after`} />
              </div>
            )}
            {img && (
              <div className={`card-bg ${cardBgClassName}`}>
                <BackgroundImage
                  Tag="div"
                  fluid={img}
                  className={`card-bg-img ${imgHeight}`}
                >
                  <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                  <ImgRatio />
                </BackgroundImage>
                <div className={`el-before`} />
                <div className={`el-after`} />
              </div>
            )}
          </div>
          <div className={`col-body ${colRight}`}>
            {(title || text) && (
              <div className={`card-body ${cardBodyClassName}`}>
                {label && (
                  <span className={`card-label ${labelClassName}`}>
                    {label}
                  </span>
                )}
                {title && (
                  <h3 className={`card-title ${titleClassName}`}>{title}</h3>
                )}
                {text && <p className={`card-text ${textClassName}`}>{text}</p>}
                {children}
              </div>
            )}
          </div>
        </div>
      </Link>
    )
  }
}

Card.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string,
  target: PropTypes.string,
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

Card.defaultProps = {
  id: "",
  variant: "basic",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  target: null,
  className: "",
}
