import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import GatsbyLink from "gatsby-link"
import GatsbyBackgroundImage from "gatsby-background-image"

import ratio11 from "./img/r1-1.png"
import ratio21 from "./img/r2-1.png"
import ratio32 from "./img/r3-2.png"
import ratio23 from "./img/r2-3.png"
import ratio54 from "./img/r5-4.png"
import ratio45 from "./img/r4-5.png"
import ratio169 from "./img/r16-9.png"

// Image Ratio
export const ImgRatio = () => {
  return (
    <>
      <img
        className="img-ratio img-ratio-1-1"
        src={ratio11}
        alt="Ratio Placeholder"
        width="100%"
      />
      <img
        className="img-ratio img-ratio-2-1"
        src={ratio21}
        alt="Ratio Placeholder"
        width="100%"
      />
      <img
        className="img-ratio img-ratio-3-2"
        src={ratio32}
        alt="Ratio Placeholder"
        width="100%"
      />
      <img
        className="img-ratio img-ratio-2-3"
        src={ratio23}
        alt="Ratio Placeholder"
        width="100%"
      />
      <img
        className="img-ratio img-ratio-5-4"
        src={ratio54}
        alt="Ratio Placeholder"
        width="100%"
      />
      <img
        className="img-ratio img-ratio-4-5"
        src={ratio45}
        alt="Ratio Placeholder"
        width="100%"
      />
      <img
        className="img-ratio img-ratio-16-9"
        src={ratio169}
        alt="Ratio Placeholder"
        width="100%"
      />
    </>
  )
}

// Media Query
export const useMedia = query => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches)
  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    media.addListener(listener)
    return () => media.removeListener(listener)
  }, [query, matches])
  return matches
}

// Link
export const Link = props => {
  if (props.target === "_blank") {
    return (
      <a
        className={
          typeof props.children === "string"
            ? "btn btn-link"
            : props.className
            ? props.className
            : ""
        }
        href={props.to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    )
  } else if (props.target === "") {
    const rawUrl = props.to && props.to
    const config = process.env.ROOT_URL
    const url = rawUrl.split(config)

    return (
      <GatsbyLink {...props} to={url[1]}>
        {props.children}
      </GatsbyLink>
    )
  } else {
    if (props.to && props.to.startsWith("/")) {
      return <GatsbyLink {...props}>{props.children}</GatsbyLink>
    } else if (props.to === "") {
      return <div {...props}>{props.children}</div>
    } else {
      return (
        <a {...props} href={props.to} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      )
    }
  }
}

// Background Image
export const BackgroundImage = props => {
  if (typeof props.fluid === "object") {
    return (
      <GatsbyBackgroundImage {...props}>{props.children}</GatsbyBackgroundImage>
    )
  } else {
    return (
      <div
        className={props.className}
        style={{
          background: `url(${props.fluid}) no-repeat center`,
          backgroundSize: "cover",
        }}
      >
        {props.children}
      </div>
    )
  }
}

BackgroundImage.propTypes = {
  fluid: PropTypes.any,
  Tag: PropTypes.string,
  className: PropTypes.string,
}

BackgroundImage.defaultProps = {
  Tag: "div",
  className: "",
}
