import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactModal from "react-modal"

export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)
  function toggle() {
    setIsShowing(!isShowing)
  }
  return {
    isShowing,
    toggle,
  }
}

export const Modal = ({
  isShowing,
  hide,
  img,
  imgClassName,
  imgAlt,
  title,
  titleClassName,
  text,
  textClassName,
  modalWrapperClassName,
  className,
  children,
}) => {
  return (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={isShowing}
      contentLabel="Modal"
      onRequestClose={hide}
      className={`${className}`}
    >
      <div className={`modal-wrapper ${modalWrapperClassName}`}>
        <div onClick={hide} className="modal-close">
          <i className="fal fa-times"></i>
        </div>
        {img && (
          <img
            src={img}
            className={`img-top img-fluid ${imgClassName}`}
            alt={imgAlt}
          />
        )}
        {title && <h3 className={`${titleClassName}`}>{title}</h3>}
        {text && <p className={`${textClassName}`}>{text}</p>}
        {children}
      </div>
    </ReactModal>
  )
}

Modal.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.bool,
  img: PropTypes.any,
  imgClassName: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  modalWrapperClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

Modal.defaultProps = {
  imgClassName: "",
  imgAlt: "Modal Image",
  titleClassName: "",
  textClassName: "",
  modalWrapperClassName: "",
  className: "",
}

// Sample of usage
//
// const { isShowing, toggle } = useModal()

// <Modal
//   isShowing={isShowing}
//   hide={toggle}
//   title="Title"
//   text="Text here"
// >
//   <Button
//     variant="primary"
//     onClick={toggle}
//    >
//     OK
//   </Button>
// </Modal>
